/** @jsx jsx */
import { jsx } from "theme-ui"
import slugify from "slugify"
import { Fragment } from "react"
import { Link, NumberBadge, Badge } from "gatsby-interface"
import article from "../../assets/icons/article.svg"
import ArticleIcon from "../../assets/icons/grey-article"

import { titleStyles } from "../../utils/styles"

import Container from "../../components/container"
import { NewDocsBreadcrumb } from "../../components/docs/breadcrumb"

import { MdSubdirectoryArrowRight } from "react-icons/md"

import DocsHomepageKeyvisual from "../../assets/docs-keyvisual-homepage.svg"
import HowToGuidesKeyvisual from "../../assets/docs-keyvisual-how-to-guides.svg"
import ConceptualGuidesKeyvisual from "../../assets/docs-keyvisual-conceptual-guides.svg"
import TutorialsKeyvisual from "../../assets/docs-keyvisual-tutorials.svg"
import ReferenceGuidesKeyvisual from "../../assets/docs-keyvisual-reference-guides.svg"

import getDocsIconComponent from "../../utils/get-docs-icon"

// number of docs to show in the side section
const DISPLAY_ITEMS = 3

const sideLinkListItemStyle = theme => ({
  listStyleImage: `url(${article})`,
  marginBottom: theme.space[3],
})

const sideLinkStyles = theme => ({
  fontWeight: 500,
  textDecoration: `none`,
  display: `inline`,
  color: theme.colors.purple[50],
})

const sectionHeaderElementStyles = theme => ({
  display: `inline-flex`,
  justifyContent: "baseline",
  alignItems: "center",
  marginBottom: theme.space[7],
})

const titleElementStyles = theme => ({
  color: theme.colors.grey[80],
  display: "flex",
  fontFamily: theme.fonts.body,
  fontSize: theme.fontSizes[5],
  marginBottom: 0,
  alignItems: "center",
  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[4],
  },
})

const iconStyles = theme => ({ marginRight: theme.space[2], flexShrink: 0 })

const linkArrowStyles = theme => ({
  color: theme.colors.grey[40],
  fontWeight: 300,
  fontSize: theme.fontSizes[3],
  marginLeft: theme.space[2],
})

const sectionDescriptionStyles = theme => ({
  color: theme.colors.grey[60],
  marginBottom: 0,
})

// SectionLink

const sectionLinkListStyle = theme => ({
  mb: 0,
  [theme.mediaQueries.desktop]: {
    mt: 0,
    display: `inline-block`,
  },
})

const seeAllLinkStyles = theme => ({
  marginLeft: -7,
  color: theme.colors.grey[50],
  textDecoration: `none`,
  ":hover, :focus": {
    color: theme.colors.grey[50],
    textDecoration: `none`,
    ".hover": { textDecoration: `underline` },
  },
})

const linkCardStyles = theme => ({
  boxShadow: theme.shadows.raised,
  padding: theme.space[7],
  margin: 0,
  verticalAlign: `top`,
  textDecoration: `none`,
  flexDirection: `column`,
  alignItems: `flex-start`,
  borderRadius: theme.radii[2],
  transition: `box-shadow ${theme.transitions.speed.default} ${theme.transitions.curve.default}`,

  "&:hover, :focus": {
    textDecoration: `none`,
    boxShadow: theme.shadows.floating,
  },

  [theme.mediaQueries.desktop]: {
    width: `50%`,
  },
})

const linkCardContainerStyles = isFullWidth => theme => [
  sectionTitleStyles(theme),
  linkCardStyles(theme),
  // hacky but safe — seems we need the original `width: 50%` from `linkCardStyles`
  { [theme.mediaQueries.desktop]: { width: isFullWidth ? "100%" : null } },
]

const sectionTitleStyles = theme => ({
  textDecoration: `none`,
  ".link-title": {
    borderBottom: `1px solid transparent`,
  },
  ":hover, :focus": {
    textDecoration: `none`,
    ".link-title": {
      borderBottom: `1px solid ${theme.colors.purple[50]}`,
    },
    ".link-title, .colored-word": {
      color: theme.colors.purple[50],
    },
    ".link-arrow": {
      color: theme.colors.purple[40],
    },
    ".badge": {
      background: theme.colors.purple[5],
      borderColor: theme.colors.purple[10],
      color: theme.colors.purple[50],
    },
  },
})

const nonLinkSectionTitles = {
  ".link-arrow": {
    display: `none`,
  },
}

const numArticlesBadgeStyles = theme => ({
  background: theme.colors.white,
  color: theme.colors.grey[50],
  fontWeight: theme.fontWeights.body,
  textDecoration: "none",
  marginLeft: theme.space[3],
  position: "relative",
  top: theme.space[1],
  fontSize: "11px",
  svg: {
    marginRight: theme.space[2],
  },
  "&:hover, :focus": {
    background: theme.colors.purple[5],
    borderColor: theme.colors.purple[10],
    color: theme.colors.purple[50],
  },
})

const advancedSectionStyles = theme => ({
  display: `grid`,
  gridTemplateColumns: `1fr`,
  gridGap: theme.space[7],
  [theme.mediaQueries.tablet]: {
    gridTemplateColumns: `1fr 1fr`,
  },
})

const sectionCardDescriptionLinksStyles = theme => ({
  display: `grid`,
  gridTemplateColumns: `1fr`,
  gridGap: theme.space[5],
  [theme.mediaQueries.desktop]: {
    gridTemplateColumns: `1fr 1fr`,
    gridGap: theme.space[10],
  },
})

const docsLandingSubtitleStyles = theme => ({
  marginTop: theme.space[7],
  [theme.mediaQueries.tablet]: {
    fontSize: theme.fontSizes[7],
  },
  [theme.mediaQueries.hd]: {
    fontSize: theme.fontSizes[8],
  },
})

const linkListStyles = theme => ({
  display: `grid`,
  gridTemplateColumns: `1fr`,
  gridGap: theme.space[5],
  [theme.mediaQueries.desktop]: {
    gridTemplateColumns: `1fr 1fr`,
    gridGap: theme.space[7],
  },
})

const pageHeaderStyles = theme => ({
  paddingTop: theme.space[8],
  [theme.mediaQueries.desktop]: {
    paddingTop: theme.space[10],
  },
})

const pageHeaderContainerDivStyles = theme => ({
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  borderBottomColor: theme.colors.blackFade[10],
  marginBottom: theme.space[10],
  display: "flex",
})

const textContainerStyles = location => theme => ({
  maxWidth: getKeyvisual(location) ? "44rem" : null,
  paddingBottom: theme.space[5],
  [theme.mediaQueries.hd]: {
    paddingRight: theme.space[10],
  },
  [theme.mediaQueries.hhd]: {
    paddingBottom: theme.space[6],
  },
})

const pageTitleStyles = theme => [
  titleStyles(theme),
  {
    [theme.mediaQueries.tablet]: {
      fontSize: theme.fontSizes[10],
    },
    [theme.mediaQueries.desktop]: {
      fontSize: theme.fontSizes[11],
    },
    [theme.mediaQueries.hd]: {
      fontSize: theme.fontSizes[11],
    },
    "& > span, & > span > span": {
      fontWeight: 800,
    },
  },
]

const descriptionHeaderStyles = theme => ({
  fontSize: theme.fontSizes[3],
  color: theme.colors.grey[80],
  [theme.mediaQueries.tablet]: {
    fontSize: theme.fontSizes[4],
  },
})

const keyVisualStyles = theme => ({
  alignSelf: "flex-end",
  display: "none",
  [theme.mediaQueries.hd]: {
    display: "block",
    marginBottom: `-36px`,
    width: 240,
  },
})

const colorFirstWord = (emphasizedColor, text) => {
  let color = theme => theme.colors.black[0]

  if (emphasizedColor === "teal") {
    color = theme => theme.colors.teal[80]
  } else if (emphasizedColor === "purple") {
    color = theme => theme.colors.purple[60]
  } else if (emphasizedColor === "magenta") {
    color = theme => theme.colors.magenta[50]
  } else if (emphasizedColor === "orange") {
    color = theme => theme.colors.orange[80]
  } else if (emphasizedColor === "blue") {
    color = theme => theme.colors.blue[50]
  }

  return emphasizedColor === "purple" ? ( // Documentation
    <span>
      {text
        .split(" ")
        .slice(0, 3)
        .join(" ")}
      &nbsp;
      <span sx={{ color }}>
        {text
          .split(" ")
          .slice(3)
          .join(" ")}
      </span>
    </span>
  ) : (
    <span>
      <span sx={{ color }} className="colored-word">
        {text.split(" ")[0]}
      </span>{" "}
      {text
        .split(" ")
        .slice(1)
        .join(" ")}
    </span>
  )
}

const getKeyvisual = location => {
  let svg

  if (location.pathname === "/docs/how-to/") {
    svg = HowToGuidesKeyvisual
  } else if (location.pathname === "/docs/reference/") {
    svg = ReferenceGuidesKeyvisual
  } else if (location.pathname === "/docs/tutorial/") {
    svg = TutorialsKeyvisual
  } else if (location.pathname === "/docs/conceptual/") {
    svg = ConceptualGuidesKeyvisual
  } else if (location.pathname === "/docs/") {
    svg = DocsHomepageKeyvisual
  }

  return svg
}

export const findCurrentDocsPage = (item, to) => {
  if (item.to === to) {
    return item
  }

  for (const subItem of item.subItems || []) {
    const pageCandidate = findCurrentDocsPage(subItem, to)
    if (pageCandidate) {
      return pageCandidate
    }
  }

  return null
}

const replaceHowTo = label =>
  label.slice(0, 7).toLowerCase() === "how to "
    ? label.slice(7, 8).toUpperCase() + label.slice(8)
    : label

const SideLink = ({ link }) => (
  <li sx={sideLinkListItemStyle}>
    <Link sx={sideLinkStyles} to={link?.to}>
      {replaceHowTo(link?.label || "")}
    </Link>
  </li>
)

const SectionHeader = ({
  HeaderElement,
  Icon,
  Badge,
  title,
  TitleElement = "h3",
  to,
  emphasizedColor,
  hasLinkStyles,
}) => (
  <HeaderElement
    to={to}
    css={theme => [
      sectionHeaderElementStyles(theme),
      to || hasLinkStyles ? sectionTitleStyles(theme) : nonLinkSectionTitles,
    ]}
  >
    <TitleElement
      id={title && slugify(title, { lower: true })}
      css={titleElementStyles}
    >
      {Icon && <Icon sx={iconStyles} />}
      <span>
        <span className="link-title">
          {emphasizedColor ? colorFirstWord(emphasizedColor, title) : title}
        </span>
        <span className="link-arrow" sx={linkArrowStyles}>
          &rarr;
        </span>
      </span>
    </TitleElement>
    {Badge}
  </HeaderElement>
)

const SectionDescription = ({ description }) => (
  <p sx={theme => sectionDescriptionStyles(theme)}>{description}</p>
)

const SectionLinks = ({
  seeAllLink,
  sideLinks,
  numItems,
  displayItems = DISPLAY_ITEMS,
}) => (
  <ul sx={theme => sectionLinkListStyle(theme)}>
    {sideLinks &&
      sideLinks
        .slice(0, displayItems)
        .map((link, i) => <SideLink link={link} key={i} />)}
    {seeAllLink && numItems - displayItems > 0 ? (
      <Link to={seeAllLink} sx={seeAllLinkStyles}>
        <MdSubdirectoryArrowRight css={{ marginRight: 8 }} />
        <span className="hover">See all {numItems}</span>
      </Link>
    ) : null}
  </ul>
)

const NumArticlesBadge = ({ numItems, longBadge }) => (
  <Badge
    Icon={ArticleIcon}
    tone="NEUTRAL"
    className="badge"
    css={numArticlesBadgeStyles}
  >
    {numItems}
    {longBadge && <span>&nbsp;{numItems > 1 ? `articles` : `article`}</span>}
  </Badge>
)

const LinkCard = ({ title, description, to, isFullWidth }) => (
  <Link to={to} css={linkCardContainerStyles(isFullWidth)}>
    <SectionHeader HeaderElement="span" title={title} hasLinkStyles={true} />
    <SectionDescription description={description} />
  </Link>
)

const AdvancedSection = ({ title, numItems, sideLinks }) => (
  <section>
    <DocsLandingSubtitle id="additional" title={title} numItems={numItems} />
    <ul css={advancedSectionStyles}>
      {sideLinks.map((link, j) => (
        <SideLink link={link} key={j} />
      ))}
    </ul>
  </section>
)

const SectionCard = ({
  Icon,
  numItems,
  title,
  description,
  sideLinks,
  seeAllLink,
  longBadge,
  emphasizedColor,
}) => (
  <section css={theme => ({ paddingBottom: theme.space[10] })}>
    <SectionHeader
      to={seeAllLink}
      HeaderElement={seeAllLink ? Link : "span"}
      TitleElement="h2"
      emphasizedColor={emphasizedColor}
      title={title}
      Icon={Icon}
      Badge={<NumArticlesBadge numItems={numItems} longBadge={longBadge} />}
    />
    <div css={sectionCardDescriptionLinksStyles}>
      <SectionDescription description={description} />
      <SectionLinks
        sideLinks={sideLinks}
        seeAllLink={seeAllLink}
        numItems={numItems}
      />
    </div>
  </section>
)

const CardFromSlug = ({ currentPage, slug }) => {
  const page = findCurrentDocsPage(currentPage, slug)
  return (
    <LinkCard
      title={page?.label}
      description={page?.description}
      to={page?.to}
    />
  )
}

const DocsLandingSubtitle = ({ id, title, numItems }) => (
  <h2 id={id || title} sx={docsLandingSubtitleStyles}>
    &nbsp;{title}&nbsp;
    <NumberBadge aria-label={`${numItems} article${numItems > 1 && "s"}`}>
      {numItems}
    </NumberBadge>
  </h2>
)

export const SubsectionList = ({ currentPage, advanced }) => (
  <Fragment>
    {currentPage.subSections.map(subSectionOrSlug =>
      subSectionOrSlug.slug ? (
        <CardFromSlug currentPage={currentPage} slug={subSectionOrSlug.slug} />
      ) : (
        <Fragment>
          <DocsLandingSubtitle
            title={subSectionOrSlug.title}
            numItems={subSectionOrSlug.routes.length}
          />
          <p sx={{ maxWidth: 600 }}>{subSectionOrSlug.description}</p>
          {subSectionOrSlug.routes.map((route, i) => (
            <CardFromSlug currentPage={currentPage} slug={route.slug} key={i} />
          ))}
        </Fragment>
      )
    )}
    {advanced ? (
      <AdvancedSection
        title={advanced.label}
        numItems={advanced.subItems.length}
        sideLinks={advanced.subItems}
      />
    ) : null}
  </Fragment>
)

export const SectionList = ({ sectionList, isDocsHomepage }) => (
  <Fragment>
    {sectionList.map((sectionOrDocType, i) => {
      // Count the number of children articles.
      // We ignore overview or additional leaf nodes
      let articleCount = 0
      sectionOrDocType.subItems.forEach(item => {
        if (
          !item.subItems &&
          ![`overview`, `additional`].includes(item.itemType)
        ) {
          articleCount += 1
        }

        if (isDocsHomepage && item.subItems) {
          articleCount += item.subItems.filter(
            i => ![`overview`, `additional`].includes(i.itemType)
          ).length
        }
      })

      return (
        <SectionCard
          longBadge={true}
          key={i}
          sideLinks={
            sectionOrDocType.mostPopular ||
            sectionOrDocType.subItems?.filter(item => !item.itemType)
          }
          numItems={articleCount} // if docs homepage, scan grandchildren, then if 0 scan children. if not docs homepage only scan children
          Icon={getDocsIconComponent(sectionOrDocType.iconLabel)}
          title={sectionOrDocType.label}
          emphasizedColor={isDocsHomepage && sectionOrDocType.emphasizedColor}
          description={
            sectionOrDocType.docTypeDescription ||
            sectionOrDocType.shortDescription ||
            sectionOrDocType.shortDescription ||
            sectionOrDocType.description
          }
          seeAllLink={
            sectionOrDocType.to ||
            (sectionOrDocType.subItems[0].itemType === "overview" &&
              sectionOrDocType.subItems[0].to)
          }
        />
      )
    })}
  </Fragment>
)

export const LinkList = ({ items, advanced }) => (
  <div css={theme => ({ marginBottom: theme.space[10] })}>
    {advanced && (
      <DocsLandingSubtitle title="Main Guides" numItems={items.length} />
    )}
    <div css={linkListStyles}>
      {items?.map((item, i) => (
        <LinkCard
          key={i}
          title={item.label}
          description={item.description}
          to={item.to}
          TitleElement={advanced ? "h3" : "h2"}
          isFullWidth={true}
        />
      ))}
    </div>
    {advanced ? (
      <AdvancedSection
        title={advanced.label}
        numItems={advanced.subItems.length}
        sideLinks={advanced.subItems}
      />
    ) : null}
  </div>
)

export const PageHeader = ({
  titleText,
  description,
  emphasizedColor,
  location,
  children,
}) => (
  <div css={pageHeaderStyles}>
    <Container>
      <div css={pageHeaderContainerDivStyles}>
        <div css={textContainerStyles(location)}>
          {location.pathname !== "/docs" && location.pathname !== "/docs/" && (
            <NewDocsBreadcrumb location={location} title={titleText} />
          )}
          <h1 css={theme => pageTitleStyles(theme)}>
            {colorFirstWord(emphasizedColor, titleText)}
          </h1>
          {(description || "").split("\n").map((para, i) => (
            <p
              key={i}
              sx={theme => descriptionHeaderStyles(theme)}
              dangerouslySetInnerHTML={{ __html: para }}
            />
          ))}
        </div>
        <img
          src={getKeyvisual(location)}
          alt=""
          css={theme => keyVisualStyles(theme)}
        />
        {children}
      </div>
    </Container>
  </div>
)
