import React from "react"
import Helmet from "react-helmet"

const JsonLd = ({ structuredData }) => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  )
}

export default JsonLd
