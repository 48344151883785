/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, Fragment } from "react"
import slugify from "slugify"

import { Link } from "gatsby-interface"

import {
  MdArrowBack as ArrowBackIcon,
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
} from "react-icons/md"
import allDocs from "./all-docs-sidebar.yaml"
import { SidebarNav, NumberBadge } from "gatsby-interface"
import {
  getCurrentDocsSection,
  isUrlInSection,
} from "../../utils/sidebar/item-list.js"
import getDocsIconComponent from "../../utils/get-docs-icon"

const getOpenDropdowns = (thisHierarchy, activeUrl) => {
  const openDropdowns = {}

  thisHierarchy.forEach((item, i) => {
    if (item.to === activeUrl) {
      openDropdowns[i] = true
    }

    // if on docs homepage open "How-to Guides"
    if (item.startOpen || i === 0) {
      openDropdowns[i] = true
    }

    if (item.subItems) {
      item.subItems.forEach(subItem => {
        if (subItem.to === activeUrl) {
          openDropdowns[i] = true
        }

        // prevents a "double toggle" that closes the section
        // when on a section overview page
        if (subItem.to === activeUrl && subItem.itemType === `overview`) {
          openDropdowns[i] = false
        }

        if (
          subItem.itemType === `additional` &&
          isUrlInSection(subItem, activeUrl)
        ) {
          openDropdowns[i] = true
        }
      })
    }
  })
  return openDropdowns
}

const CalmNumberBadge = ({ number }) => (
  <NumberBadge
    css={theme => ({
      background: theme.colors.grey[5],
      fontSize: "11px",
      fontWeight: theme.fontWeights.body,
      marginLeft: theme.space[2],
    })}
  >
    {number}
  </NumberBadge>
)

const ExpandableButton = ({
  onClick,
  index,
  labelText,
  allCardChildren,
  startOpen,
  numChildren,
}) => {
  const [isExpanded, setIsExpanded] = useState(!!startOpen)
  return allCardChildren && !labelText ? (
    "" // this displays an "invisble" parent label
  ) : (
    <button
      sx={{
        alignItems: "center",
        backgroundColor: `transparent`,
        border: `none`,
        color: isExpanded ? "grey.70" : "grey.50",
        cursor: `pointer`,
        display: "flex",
        fontWeight: isExpanded ? "semiBold" : null,
        padding: 0,
        // -32px = 20px icon (font-size: 4) + margin-right: 12 (space.4)
        marginLeft: -8,
        svg: {
          color: "grey.40",
          display: "block",
          fontSize: 4,
          marginRight: 4,
        },
      }}
      onClick={e => {
        setIsExpanded(!isExpanded)
        onClick(index)
        e.preventDefault()
      }}
      onKeyPress={e => {
        if (e.key === " ") {
          e.preventDefault()
          return
        }
        setIsExpanded(!isExpanded)
        onClick(index)
        e.preventDefault()
      }}
      aria-label={labelText}
      aria-expanded={isExpanded}
    >
      {isExpanded ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}
      {labelText} <CalmNumberBadge number={numChildren} />
    </button>
  )
}

const setSidebarState = (hierarchy, openDropdowns, setDropdown, activeUrl) => {
  // initial element has an icon, is always "open" and displays its "Overview" child

  const DocsIconComponent = getDocsIconComponent(hierarchy[0].iconLabel)
  hierarchy[0].Icon = () => (
    <span
      sx={{
        marginLeft: -40,
        marginRight: `10px`,
        verticalAlign: `middle`,
      }}
    >
      <DocsIconComponent />
    </span>
  )

  hierarchy[0].toggled = true
  if (hierarchy[0].subItems) {
    hierarchy[0].subItems[0].active = hierarchy[0].subItems[0].to === activeUrl
  }

  // subsequent elements are open depending on whether
  // they are the current page OR have been toggled to be open.

  // if they have children, they have a chevron next to text
  // and are a button rather than a link

  hierarchy.slice(1).forEach((item, i) => {
    item.toggled = openDropdowns[i + 1]
    item.active = item.to === activeUrl

    if (item.subItems) {
      if (typeof item.label === "string") {
        // Count the number of children articles.
        // We ignore overview or additional leaf nodes
        let articleCount = 0
        item.subItems.forEach(i => {
          if (![`overview`, `additional`].includes(i.itemType)) {
            // Leaf node.
            if (!i.subItems) {
              articleCount += 1
            }
            if (i.subItems) {
              // Leaf nodes.
              i.subItems.forEach(isub2 => {
                if (![`overview`, `additional`].includes(isub2.itemType)) {
                  articleCount += 1
                }
              })
            }
          }
        })
        item.labelText = item.label
        item.label = (
          <ExpandableButton
            startOpen={item.startOpen || isUrlInSection(item, activeUrl)}
            labelText={item.labelText}
            allCardChildren={item.allCardChildren}
            // Don't count the "Overview" or "Additional Guides"
            numChildren={articleCount}
            index={i + 1}
            onClick={j => {
              setDropdown({ ...openDropdowns, [j]: !openDropdowns[j] })
            }}
          />
        )
      }

      item.subItems.forEach(subItem => {
        subItem.active = subItem.to === activeUrl
        if (subItem.itemType === `additional`) {
          subItem.label = (
            <Fragment>
              Additional Guides{" "}
              <CalmNumberBadge number={subItem.subItems.length} />
            </Fragment>
          )
        } else if (subItem.subItems) {
          // Count articles.
          // At this depth, we only have leaf nodes so we just
          // filter out the overview/additional nodes.
          const articleCount = subItem.subItems.filter(
            i => ![`overview`, `additional`].includes(i.itemType)
          ).length
          if (!subItem.labelText) {
            subItem.labelText = subItem.label
            subItem.label = (
              <Fragment>
                {subItem.label} <CalmNumberBadge number={articleCount} />
              </Fragment>
            )
          }
          if (!subItem.to) {
            subItem.to = `${item.to}#${slugify(subItem.labelText, {
              lower: true,
            })}`
          }
        }
      })
    } else {
      if (typeof item.label === "string") {
        item.label = (
          <span
            css={theme => ({
              paddingLeft: `calc(${theme.space[6]} + 1px)`,
              marginLeft: `calc(-${theme.space[6]} - 2px)`,
              borderLeft: `1px solid ${theme.colors.grey[30]}`,
            })}
            className="card-child-item"
          >
            {item.label}
          </span>
        )
      }
    }
  })
}

const getCurrentHierarchy = (activeUrl, allDocs) => {
  // handles displaying "Overview" link properly
  const flattenSection = (section, isTopLevel = false) => {
    return [
      {
        label: section.label,
        to: section.to,
        startOpen: section.startOpen,
        iconLabel: section.iconLabel,
        itemType: section.itemType,
        subItems: section.subItems && [section.subItems[0]],
      },
      ...(section.allCardChildren && isTopLevel // Tutorial or Reference Guides
        ? [
            {
              label: "",
              to: "",
              allCardChildren: true,
              startOpen: true,
              subItems: section.subItems
                .slice(1)
                .filter(item => !item.subItems),
            },
          ]
        : (section.subItems && section.subItems.slice(1)) || []),
    ]
  }

  // removes all irrelevant object keys
  const stripItems = flatSection => {
    return (
      flatSection &&
      flatSection.map(item => ({
        label: item.label,
        to: item.to,
        startOpen: item.startOpen,
        iconLabel: item.iconLabel,
        itemType: item.itemType,
        allCardChildren: item.allCardChildren,
        ...(item.subItems ? { subItems: stripItems(item.subItems) } : {}),
      }))
    )
  }

  const currentDocsSection = getCurrentDocsSection(activeUrl)

  // navBarIndex == 0 means 'Documentation'
  const sectionData =
    currentDocsSection.navBarIndex === 0
      ? allDocs[0]
      : allDocs[0].subItems[currentDocsSection.navBarIndex + 1]

  return stripItems(flattenSection(sectionData, true))
}

// What does this component do? you ask.
//
// This component takes the data in all-docs-sidebar.yaml, and
// transforms it to the format that our gatsby-interface SidebarNav expects.
// To do this, it must get display the appropriate part of
// hierarchy based on the user's location, with the correct icons and labels,
// style and format them appropriately. It stores and allows manipulation of
// the dropdown toggled state, since the SidebarNav knows about toggling but
// has no ability to toggle. In addition, it does some slighly janky style overriding
// to get the right amount of padding, margin, bolding of open dropdowns,
// and grey line (border) extension.

export const DocsSidebarNav = ({ activeUrl }) => {
  const currentHierarchy = getCurrentHierarchy(activeUrl, allDocs)
  const [openDropdowns, setDropdown] = useState(
    getOpenDropdowns(currentHierarchy, activeUrl)
  )

  setSidebarState(currentHierarchy, openDropdowns, setDropdown, activeUrl)

  return (
    <div
      sx={theme => ({
        py: theme.space[10],
        ml: theme.space[8],
        background: theme.colors.white,
        "& > nav > ul > li:nth-child(1)": {
          textTransform: `uppercase`,
          fontWeight: 500,
          fontSize: theme.fontSizes[0],
          pb: 0,
          "& > ul": {
            textTransform: `none`,
            fontWeight: 300,
            fontSize: theme.fontSizes[1],
            pb: theme.space[6], // extra margin after "Overview" of open section

            // extend the grey line border down from top "Overview" towards next element in list below.
            pt: 0,
            mt: theme.space[4],
            paddingLeft: `calc(${theme.space[6]} + 1px)`,
            marginLeft: `calc(-${theme.space[6]} - 2px)`,
            borderLeft: `1px solid ${theme.colors.grey[30]}`,
          },
        },
        "& > nav > ul > li:nth-child(1) + li:nth-child(2)": {
          pt: 0,

          // include margin if first element in sidebar under top overview is a toggle dropdown
          "& > a > button, & > button": {
            // in case "to" field is included & button is wrapped in an <a>
            mt: theme.space[4],
          },
          "& > a > span": {
            // if first element after "Overview" is "Quick Start", extend grey vertical line up
            pt: theme.space[4],
            display: `inline-block`,
          },

          // if first element after overview under top interview is a card in virtual allCardChildren list,
          // extend the gray line up a bit from that element while providing some visual padding
          "& > ul": {
            pt: 0,
            "& > li:nth-child(1) > *": {
              display: `inline-block`,
              pt: theme.space[4],
            },
          },
        },
        "& > nav > ul > li > ul": {
          pt: theme.space[4],
        },
        // extra margin at bottom of open dropdown
        "& > nav > ul > li:not(:nth-child(1)) > ul:last-of-type": {
          mb: theme.space[5],
        },
        '& > nav > ul > li[openstate="open"]': {
          fontWeight: 500,
        },
      })}
    >
      {!(
        activeUrl === `/docs/` ||
        activeUrl === `/docs` ||
        activeUrl === `/docs/quick-start/` ||
        activeUrl === `/docs/quick-start`
      ) ? (
        <nav>
          <Link
            sx={{
              mb: 12,
              textDecoration: `none`,
              fontSize: theme => theme.fontSizes[1],
              color: theme => theme.colors.grey[50],
              ":hover": { color: theme => theme.colors.grey[50] },
            }}
            to="/docs/"
          >
            <ArrowBackIcon /> &nbsp; Docs
          </Link>
        </nav>
      ) : null}
      <SidebarNav
        aria-label="Secondary Navigation"
        options={currentHierarchy}
      />
    </div>
  )
}
