import * as React from "react"
import JsonLd from "./JsonLd"

/*
Structured Data Breadcrumbs use the schema defined in https://developers.google.com/search/docs/data-types/breadcrumb
*/

export interface BreadcrumbListItem {
  name: string
  item: string // item is a URL to the page
}

export interface BreadcrumbsProps {
  listName: string
  itemList: Array<BreadcrumbListItem>
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  listName,
  itemList,
}) => {
  return (
    <JsonLd
      structuredData={{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        name: listName,
        itemListElement: itemList.map((item, idx) => ({
          "@type": "ListItem",
          position: idx + 1,
          ...item,
        })),
      }}
    />
  )
}
